<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('callcenter.lead') }}</div>
              </div>
            </template>
            <octo-header-button
              :buttons="leadButtons"
              :title="registry.surname + ' ' + registry.name"
              @onReleaseCallCenterLead="releaseCallCenterLead"
              @onOpenAssignCallCenterLeadModal="modalKey++; showModal = true;"
            />
            <call-center-lead-summary
              :call-center-lead="callCenterLead"
              :key="`call-center-lead-summary-${callCenterLeadDataKey}`"
            />
            <lead-tab-pane
              v-if="callCenterLead.lead"
              :lead="callCenterLead.lead"
              :read-only="false"
              :key="`lead-tab-pane-${callCenterLeadDataKey}`"
            />
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.registry') }}</div>
              </div>
            </template>
            <octo-header-button
              :buttons="registryButtons"
              :title="registry.surname + ' ' + registry.name"
              @onOpenRegistry="$router.push({name: 'registries.show', params: {id: registry.id}})"
            />
            <registry-tab-pane
              :registry="registry"
              :key="`registry-tab-pane-${callCenterLeadDataKey}`"
            />
          </tab-pane>
        </tabs>
      </div>

      <modal centered :show.sync="showModal">
        <h5 slot="header" class="modal-title text-uppercase">
          {{ $t('lead.lead_assignment') }}
        </h5>
        <assign-call-center-lead-modal ref="assignCallCenterLeadModal" :key="`modal-${modalKey}`"/>
        <template slot="footer">
          <base-button link @click="assignFreeCallCenterLead">
            <div class="text-capitalize">{{ $t('common.assign') }}</div>
          </base-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import {TabPane, Tabs} from '@/components';
import CallCenterLead from "@/models/callCenterLead";
import Registry from "@/models/registry";
import {mapGetters} from "vuex";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import Modal from "@/components/Modal";
import LeadStatuses from "../CallCenter/resources/leadStatuses";
import AssignCallCenterLeadModal from "./components/AssignCallCenterLeadModal";
import RegistryTabPane from "@/components/TabPanels/RegistryTabPane";
import LeadTabPane from "@/components/TabPanels/LeadTabPane";
import CallCenterLeadSummary from "@/pages/Leads/components/CallCenterLeadSummary";
import {permissionService} from "@/util/permission-service";

export default {
  name: "ShowLeadProgressPage",
  components: {
    CallCenterLeadSummary,
    LeadTabPane,
    RegistryTabPane,
    AssignCallCenterLeadModal,
    Modal,
    OctoHeaderButton,
    Tabs,
    TabPane
  },
  data() {
    return {
      endpoints: endpoints,
      callCenterLead: this.$_.cloneDeep(CallCenterLead),
      callCenterLeadDataKey: 1,
      leadHistoryKey: 1,
      showModal: false,
      registry: this.$_.cloneDeep(Registry),
      leadEventLogs: [],
      leadStatuses: LeadStatuses,
      modalKey: 1
    }
  },
  computed: {
    ...mapGetters({
      operators: 'common/operators',
      users: 'common/users'
    }),

    leadButtons() {
      const buttons = [];

      if (this.callCenterLead.status === LeadStatuses.free.value) {
        buttons.push({label: 'assign', onClick: 'onOpenAssignCallCenterLeadModal'});
      } else {
        buttons.push({label: 'release', onClick: 'onReleaseCallCenterLead'});
      }

      return buttons;
    },

    registryButtons() {
      const buttons = [];

      if (permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE])) {
        buttons.push({label: 'open', onClick: 'onOpenRegistry'});
      }

      return buttons;
    }
  },
  methods: {

    releaseCallCenterLead() {
      this.$fullLoading.show();
      this.$api.put(endpoints.CALL_CENTER_LEAD_RELEASE.replace('{id}', this.callCenterLead.id))
        .then((resp) => {
          this.callCenterLead = resp?.data?.data;
          this.registry = this.callCenterLead?.lead?.registry || this.$_.cloneDeep(Registry);
          this.callCenterLeadDataKey++;
          this.leadHistoryKey++;
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    async assignFreeCallCenterLead() {
      try {
        const data = await this.$refs.assignCallCenterLeadModal.validate();

        this.$fullLoading.show();

        const resp = await this.$api.put(
          endpoints.CALL_CENTER_LEAD_ASSIGN_FREE.replace('{id}', this.callCenterLead.id),
          data
        );

        this.callCenterLead = resp?.data?.data;
        this.registry = this.callCenterLead?.lead?.registry || this.$_.cloneDeep(Registry);
        this.callCenterLeadDataKey++;
        this.leadHistoryKey++;
        this.showModal = false;
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});

      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    }

  },
  beforeMount() {
    this.$fullLoading.show();

    this.$api.get(endpoints.CALL_CENTER_LEAD_SHOW.replace('{id}', this.$route.params.id))
      .then((resp) => {
        this.callCenterLead = resp.data.data;
        this.registry = this.callCenterLead?.lead?.registry || this.$_.cloneDeep(Registry);
        this.callCenterLeadDataKey++;
        this.leadHistoryKey++;
      })
      .catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        this.$router.push({name: 'leads.in_progress'})
      })
      .finally(() => {
        this.$fullLoading.hide();
      })
  },

}
</script>

<style scoped>

</style>
