const CallCenterLeadStatuses = {
  free: {
    value: 'free',
  },
  public: {
    value: 'public',
  },
  processing: {
    value: 'processing',
  },
  private: {
    value: 'private',

  },
  unattainable: {
    value: 'unattainable',

  }
};

export default CallCenterLeadStatuses
