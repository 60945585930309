import { render, staticRenderFns } from "./AssignCallCenterLeadModal.vue?vue&type=template&id=5f839746&scoped=true&"
import script from "./AssignCallCenterLeadModal.vue?vue&type=script&lang=js&"
export * from "./AssignCallCenterLeadModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f839746",
  null
  
)

export default component.exports