<template>
  <ValidationObserver ref="assignCallCenterLeadModal">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <ValidationProvider name="status" rules="required" v-slot="{ passed, failed, errors }">
            <base-input class="mb-0 flex-grow-1"
                        :label="$t('fields.status')"
                        :error="errors[0]"
                        :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]"
            >
              <el-select
                v-model="selectedStatus"
                class="select-default text-uppercase"
                :placeholder="$t('fields.choose')"
              >
                <el-option
                  v-for="status in statuses"
                  class="select-default text-uppercase"
                  :key="status"
                  :value="status"
                  :label="$t('callcenter.status.' + status)"
                />
              </el-select>
            </base-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="selectedStatus === callCenterLeadStatuses.private.value">
          <ValidationProvider name="operator" rules="required" v-slot="{ passed, failed, errors }">
            <base-input class="mb-0 flex-grow-1"
                        :label="$t('fields.operator')"
                        :error="errors[0]"
                        :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]">
              <el-select
                v-model="operatorId"
                class="select-default text-uppercase"
                :placeholder="$t('fields.choose')"
              >
                <el-option
                  v-for="option in operators"
                  class="select-default text-uppercase"
                  :key="option.id"
                  :value="option.id"
                  :label="$options.filters.optional(users[option.id], 'lastname') + ' ' +
                        $options.filters.optional(users[option.id], 'firstname')"
                >
                </el-option>
              </el-select>
            </base-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row" v-if="
        selectedStatus === callCenterLeadStatuses.public.value
        || selectedStatus === callCenterLeadStatuses.private.value
      ">
        <div class="col-12 col-md-6">
          <ValidationProvider name="date" rules="required" v-slot="{ passed, failed, errors }">
            <base-input :label="$t('fields.date')"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              <el-date-picker
                v-model="recallDate"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                :placeholder="$t('fields.date')"
                :picker-options="{firstDayOfWeek: 1}"
              />
            </base-input>
          </ValidationProvider>
        </div>
        <div class="col-12 col-md-6">
          <ValidationProvider name="hour" rules="required" v-slot="{ passed, failed, errors }">
            <base-input :label="$t('fields.hour')"
                        class="flex-grow-1"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              <el-time-select
                v-model="recallTime"
                format="HH:mm"
                value-format="HH:mm"
                :clearable="false"
                :picker-options="pickerOptions"
              />
            </base-input>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import {mapGetters} from "vuex";
import {Select, Option, DatePicker, TimeSelect} from "element-ui"
import CallCenterLeadStatuses from "../../CallCenter/resources/callCenterLeadStatuses";

export default {
  name: "AssignCallCenterLeadModal",
  components: {
    ListGroupTitleSectionComponent,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
  },
  data() {
    return {
      callCenterLeadStatuses: CallCenterLeadStatuses,
      operatorId: null,
      recallDate: '',
      recallTime: '',
      selectedStatus: '',
    }
  },
  props: {
    statuses: {
      type: Array,
      default: () => [
        CallCenterLeadStatuses.public.value,
        CallCenterLeadStatuses.private.value
      ]
    }
  },
  computed: {
    ...mapGetters({
      operators: 'common/operators',
      users: 'common/users',
      settings: 'common/settings',
    }),
    pickerOptions() {
      return {
        start: this.settings?.callcenter_calendar_start + ':00',
        end: this.settings?.callcenter_calendar_end + ':00',
        step: '00:30',
      }
    }
  },
  methods: {
    validate() {
      if (this.selectedStatus === this.callCenterLeadStatuses.free.value) {
        return {
          status: this.selectedStatus,
          deadline: null,
          operatorId: null
        }
      }

      return new Promise((resolve, reject) => {
        this.$refs.assignCallCenterLeadModal.validate()
          .then((success) => {
            if (success) {
              resolve({
                status: this.selectedStatus,
                deadline: this.$moment(this.recallDate + ' ' + this.recallTime).format('YYYY-MM-DD HH:mm:ss'),
                operatorId: this.selectedStatus === this.callCenterLeadStatuses.private.value
                    ? this.operatorId
                    : null
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }
}
</script>

<style scoped>

</style>
