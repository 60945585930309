<template>
  <div>
    <card body-classes="standard-card-body" class="shadow">
      <template slot="header">
        <h4 class="card-title text-capitalize">{{ $t('callcenter.call_center_lead_data') }}</h4>
      </template>
      <div class="row">
        <div class="col-12 col-md-6">
          <ul class="list-group list-group-flush">
            <list-group-item-component :label=" $t('datatable.operator') ">
              <template slot="value">
                <label-theme-component v-if="callCenterLead.user_id">
                  {{ users[callCenterLead.user_id] | optional('lastname') }}
                  {{ users[callCenterLead.user_id] | optional('firstname') }}
                </label-theme-component>
              </template>
            </list-group-item-component>
            <list-group-item-component
              :label="$t('fields.campaign')"
              :value="callCenterLead | optional('campaign.name')"
            />
          </ul>
        </div>
        <div class="col-12 col-md-6">
          <ul class="list-group list-group-flush">
            <list-group-item-component :label=" $t('fields.status')">
              <template slot="value">
                <badge :type="callCenterLead.status | callCenterLeadStatusColor" class="text-uppercase mb-0">
                  {{ $t('callcenter.status.' + callCenterLead.status) }}
                </badge>
              </template>
            </list-group-item-component>
            <list-group-item-component :value="callCenterLead.deadline | datetime" :label="$t('callcenter.deadline')"/>
          </ul>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import CallCenterLead from "@/models/callCenterLead";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Badge from "@/components/Badge";

export default {
  name: "CallCenterLeadSummary",
  components: {Badge, ListGroupItemComponent, LabelThemeComponent, OctoIcon},
  props: {
    callCenterLead: {
      type: Object,
      default: () => this.$_.cloneDeep(CallCenterLead)
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    }),
  }
}
</script>

<style scoped>

</style>
